import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { faBuildingCircleCheck } from '@fortawesome/pro-regular-svg-icons';

import { dateValueToText, stringValueToText } from '../utils';
import Section from '../../../../shared_elements/components/Section';

interface CspDetailsSectionProps {
  street?: string;
  ein?: string;
  city?: string;
  website?: string;
  state?: string;
  stockSymbol?: string;
  postalCode?: string;
  stockExchange?: string;
  country?: string;
  createDate?: string;
}

const Content = styled.div`
  display: grid;
  grid-template-columns: 164px 1fr 164px 1fr;
  row-gap: 12px;
  padding-top: 30px;
  border-top: 1px solid #cccfd0;
  font-family: Roboto, sans-serif;
`;

const Field = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const Value = styled.span`
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const CspDetailsSection: FunctionComponent<CspDetailsSectionProps> = ({
  street,
  ein,
  city,
  website,
  state,
  stockSymbol,
  postalCode,
  stockExchange,
  country,
  createDate,
}) => {
  return (
    <Section
      icon={faBuildingCircleCheck}
      title="CSP Details"
      testId="cspDetailsSection"
    >
      <Content>
        <Field>Address/Street:</Field>
        <Value data-testid="street">{stringValueToText(street)}</Value>

        <Field>{country === 'US' ? 'EIN' : 'Tax Number/ID'}:</Field>
        <Value data-testid="ein">{stringValueToText(ein)}</Value>

        <Field>City:</Field>
        <Value data-tesid="city">{stringValueToText(city)}</Value>

        <Field>Website:</Field>
        <Value data-testid="website">{stringValueToText(website)}</Value>

        <Field>State:</Field>
        <Value data-testid="state">{stringValueToText(state)}</Value>

        <Field>Stock Symbol:</Field>
        <Value data-testid="stockSymbol">
          {stringValueToText(stockSymbol)}
        </Value>

        <Field>{country === 'US' ? 'ZIP Code' : 'Postal Code'}:</Field>
        <Value data-testid="postalCode">{stringValueToText(postalCode)}</Value>

        <Field>Stock Exchange:</Field>
        <Value data-testid="stockExchange">
          {stringValueToText(stockExchange)}
        </Value>

        <Field>Country:</Field>
        <Value data-testid="country">{stringValueToText(country)}</Value>

        <Field>Registered on:</Field>
        <Value data-testid="createDate">{dateValueToText(createDate)}</Value>
      </Content>
    </Section>
  );
};

export default CspDetailsSection;
