import { globalGetService } from '../utils/globalApiServices';

export const getArticle = async () => {
  const response = await globalGetService('csp/cms/article');
  if (response && response.ok) {
    return response.data;
  }
};

export const getHelpAsides = async () => {
  const response = await globalGetService('csp/cms/helpAsides');
  if (response && response.ok) {
    return response.data;
  }
};

export const getSimpleContent = async () => {
  const response = await globalGetService('csp/cms/simpleContent');
  if (response && response.ok) {
    return response.data;
  }
};

export const getTermsAndConditions = async () => {
  const response = await globalGetService('csp/cms/termsAndConditions');
  if (response && response.ok) {
    return response.data;
  }
};
