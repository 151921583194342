import { Button, Flex } from 'portal-commons';
import { faAddressBook } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, makeStyles } from '@material-ui/core';

import { BrandDetail } from '../../types';
import { convertTimeWithTimezone } from '../../../../../utils/time';
import { resend2faEmail } from '../../apis';
import { toastFlashMessage } from '../../../../../utils';

const useStyles = makeStyles({
  value: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
  },
  icon: {
    width: '22px',
    height: '22px',
    objectFit: 'cover',
  },
});

interface BusinessContactDetailsProps {
  brand: BrandDetail;
}

const BusinessContactDetails: React.FC<BusinessContactDetailsProps> = ({
  brand,
}) => {
  const handleResend = async () => {
    const response = await resend2faEmail(brand.uid!);
    if (response) {
      toastFlashMessage('2FA Sent', 'success');
    }
  };

  return (
    <>
      <div className="details-heading contact-details">
        <div className="title" style={{ width: '100%' }}>
          <Flex
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: '8px',
            }}
          >
            <FontAwesomeIcon icon={faAddressBook} size="xl" />
            <h3 className="heading1" style={{ flex: 1 }}>
              Business Contact Details
            </h3>
            {brand.businessContactEmail &&
              !brand.businessContactEmailVerifiedDate && (
                <Button shape="square" size="small" onClick={handleResend}>
                  Resend 2FA Email
                </Button>
              )}
          </Flex>
        </div>
      </div>
      <Grid container data-testid="businessContactDetails">
        <Grid item xs={6}>
          <Grid className="detail-item" container spacing={3}>
            <Grid item xs={5}>
              <h3 className="paragraph">Email:</h3>
            </Grid>
            <Grid item xs={7}>
              <h3 className="paragraph">
                <span>{brand.businessContactEmail ?? 'N/A'}</span>
              </h3>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className="detail-item" container spacing={3}>
            <Grid item xs={5}>
              <h3 className="paragraph">Email Verified Date:</h3>
            </Grid>
            <Grid item xs={7}>
              <h3 className="paragraph">
                <span>
                  {brand.businessContactEmailVerifiedDate
                    ? convertTimeWithTimezone(
                        brand.businessContactEmailVerifiedDate,
                        undefined,
                        'MM/DD/YYYY'
                      )
                    : 'N/A'}
                </span>
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessContactDetails;
