import { Button, Table, TableFilter } from 'portal-commons';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Grid, withStyles } from '@material-ui/core';

import { getBrandsList, getBrandIdentityStatus } from '../apiServices';
import { BrandListingRow, EinErrorModal } from '../components';
import { BrandFilterErrorCode as filterErrorCode } from '../../index';
import { getMnoList } from '../../Campaigns/apiServices';
import { S3_ASSETS_PATH, ENTITY_TYPES } from '../../../../constants';
import { AuthenticationPlusCompliantOptions } from '../../../../constants/options';
import Loader from '../../../../shared_elements/containers/Loader';
import { globalGetService } from '../../../../utils/globalApiServices';
import { fieldValidation } from '../../../../utils/validator';

import '../../../../assets/styles/brand-listing-module.scss';

const countryOptions = countryList()
  .getData()
  .filter((country) => !['US', 'CU', 'IR', 'KP', 'SY'].includes(country.value));
countryOptions.unshift({ value: 'US', label: 'United States' });

const filterConfigs = {
  brandUid: {
    type: 'text',
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  displayName: {
    type: 'text',
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 260,
  },
  country: {
    type: 'dropdown',
    label: 'Country',
    placeholder: 'Select country',
    options: countryOptions,
    width: 230,
  },
  entityType: {
    type: 'dropdown',
    label: 'Entity Type',
    placeholder: 'Select entity type',
    options: ENTITY_TYPES,
    width: 230,
  },
  ein: {
    type: 'text',
    label: 'EIN/Tax number',
    placeholder: 'Enter EIN/Tax number',
    width: 150,
  },
  universalEin: {
    type: 'text',
    label: 'Universal EIN',
    placeholder: 'Enter Universal EIN',
    width: 150,
  },
  identityStatus: {
    type: 'dropdown',
    label: 'Identity Status',
    placeholder: 'Select identity status',
    options: [],
    width: 230,
  },
  referenceId: {
    type: 'text',
    label: 'Reference ID',
    placeholder: 'Enter reference id',
    width: 150,
    error: false,
    helperText: '',
  },
  authenticationPlusCompliant: {
    type: 'dropdown',
    label: 'Auth+ Compliant',
    placeholder: 'Select auth+ compliant',
    options: AuthenticationPlusCompliantOptions,
    width: 230,
  },
};

const headRows = [
  { id: 'displayName', label: 'BRAND NAME', sortable: true },
  { id: 'uid', label: 'BRAND ID', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'entityType', label: 'ENTITY TYPE', sortable: false },
  { id: 'country', label: 'COUNTRY', sortable: true },
  {
    id: 'authenticationPlusCompliant',
    label: 'AUTH+ COMPLIANT',
    sortable: false,
  },
  { id: 'identityStatus', label: 'IDENTITY STATUS', sortable: false },
  { id: 'brandSuspended', label: 'BRAND SUSPENDED', sortable: false },
];

const styles = {
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
  spacing: {
    flex: 1,
  },
};

class BrandList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      brandInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      openFilter: false,
      error: {
        ein: '',
      },
      einVal: '',
      einModal: false,
      einBrandInfo: '',
      mnos: [],
      filtersShown: false,
      // configs and values used by table filter component
      filterConfigs,
      candidateFilters: {},
      appliedFilters: {},
      // additional filters implicitly set by default
      additionalFilters: {},
    };
    this.getBrandsList = getBrandsList.bind(this);
    this.getBrandIdentityStatus = getBrandIdentityStatus.bind(this);
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Brands';
    this.getBrandIdentityStatus();
    this.initialize();
    this.updateFiltersAndFetchCampaigns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateFiltersAndFetchCampaigns(prevProps.location.search);
    }
  }

  initialize = async () => {
    try {
      const mnos = await getMnoList();
      mnos.sort((a, b) => {
        if (a.osrBitmaskIndex > b.osrBitmaskIndex) {
          return 1;
        } else if (a.osrBitmaskIndex < b.osrBitmaskIndex) {
          return -1;
        }
        return 0;
      });
      this.setState({
        mnos,
      });
    } catch (e) {}
  };

  updateFiltersAndFetchCampaigns = (previousSearch = '') => {
    const { search } = this.props.location;
    const { candidateFilters, appliedFilters, additionalFilters } = this.state;
    const queries = queryString.parse(search, {
      decode: true,
    });
    const previousQueries = queryString.parse(previousSearch, {
      decode: true,
    });
    const queryEntries = Object.entries(queries);
    if (queryEntries.length > 0) {
      const newCandidateFilters = { ...candidateFilters };
      Object.keys(newCandidateFilters).forEach((key) => {
        if (queries[key] === undefined && previousQueries[key] !== undefined) {
          delete newCandidateFilters[key];
        }
      });
      const newAppliedFilters = { ...appliedFilters };
      Object.keys(filterConfigs).forEach((key) => {
        if (queries[key]) {
          if (!previousSearch) {
            newCandidateFilters[key] = queries[key];
          }
          newAppliedFilters[key] = queries[key];
        } else {
          delete newAppliedFilters[key];
        }
      });
      const newAdditionalFilters = {
        sortField: 'createDate',
        ascendingOrder: false,
        status: 'ACTIVE',
        ...additionalFilters,
      };
      queryEntries.forEach(([key, value]) => {
        if (!filterConfigs[key] && key !== 'page') {
          newAdditionalFilters[key] =
            key === 'ascendingOrder' ? JSON.parse(value) : value;
        }
      });
      this.setState({
        candidateFilters: newCandidateFilters,
        appliedFilters: newAppliedFilters,
        additionalFilters: newAdditionalFilters,
      });
      this.getBrandsList({
        ...newAppliedFilters,
        ...newAdditionalFilters,
        page: queries.page ? queries.page : 1,
      });
    } else {
      const newAdditionalFilters = {
        sortField: 'createDate',
        ascendingOrder: false,
        status: 'ACTIVE',
      };
      this.setState({
        candidateFilters: {},
        appliedFilters: {},
        additionalFilters: newAdditionalFilters,
      });
      this.getBrandsList({
        ...newAdditionalFilters,
      });
    }
  };

  fetchBrandSuggestions = (query = {}) => {
    globalGetService('csp/brands/suggestions', { ...query, limit: 20 }).then(
      (response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState((prevState) => ({
            filterConfigs: {
              ...prevState.filterConfigs,
              displayName: {
                ...prevState.filterConfigs.displayName,
                suggestions: response.data.map(
                  (suggestion) => suggestion.brandName
                ),
              },
            },
          }));
        }
      }
    );
  };

  handleChangePage = (newPage) => {
    const { appliedFilters, additionalFilters } = this.state;
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilters, ...additionalFilters, page: newPage },
        { encode: true }
      )}`,
    });
  };

  createSortHandler = (sortField) => {
    const { appliedFilters, brandInfo, additionalFilters } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (brandInfo.totalRecords) {
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            ...appliedFilters,
            ...additionalFilters,
            page: brandInfo.page,
            sortField,
            ascendingOrder: !additionalFilters.ascendingOrder,
          },
          { encode: true }
        )}`,
      });
    }
  };

  handleTableFilterToggle = () => {
    const { filtersShown } = this.state;
    this.setState({
      filtersShown: !filtersShown,
    });
  };

  resetFilterConfigsError = (newValues) => {
    const { filterConfigs: tempFilterConfigs, candidateFilters } = this.state;
    Object.keys(newValues).forEach((key) => {
      if (
        tempFilterConfigs[key]?.error &&
        newValues[key] !== candidateFilters[key]
      ) {
        tempFilterConfigs[key].error = false;
        tempFilterConfigs[key].helperText = '';
      }
    });
    this.setState({ filterConfigs: tempFilterConfigs });
  };

  validateFilters = (values) => {
    const { filterConfigs: tempFilterConfigs } = this.state;
    Object.keys(values).forEach((key) => {
      if (filterErrorCode[key]) {
        const code = fieldValidation({
          ...filterErrorCode[`${key}Obj`],
          fieldval: values[key],
        });
        if (code !== 0) {
          tempFilterConfigs[key].error = true;
          tempFilterConfigs[key].helperText = filterErrorCode[key][code];
        }
      }
    });
    this.setState({
      filterConfigs: tempFilterConfigs,
    });
    return Object.keys(values).every((key) => !tempFilterConfigs[key].error);
  };

  handleCandidateFiltersChange = (values) => {
    const { filterConfigs, candidateFilters } = this.state;
    this.resetFilterConfigsError(values);
    this.setState({
      candidateFilters: values,
    });
    if (values.displayName !== candidateFilters.displayName) {
      if (values.displayName && values.displayName.length > 1) {
        this.fetchBrandSuggestions({ prefix: values.displayName });
      } else {
        this.setState({
          filterConfigs: {
            ...filterConfigs,
            displayName: {
              ...filterConfigs.displayName,
              suggestions: [],
            },
          },
        });
      }
    }
  };

  handleAppliedFiltersChange = (values) => {
    if (!this.validateFilters(values)) {
      return;
    }
    if (Object.keys(values).length === 0) {
      this.resetFilterConfigsError(this.state.filterConfigs);
    }

    this.setState({
      appliedFilters: values,
    });

    this.props.history.push({
      search: `?${queryString.stringify(
        { ...values, ...this.state.additionalFilters },
        { encode: true }
      )}`,
    });
  };

  render() {
    const { history, classes, access } = this.props;
    const {
      brandInfo,
      tableLoader,
      loader,
      einBrandInfo,
      einModal,
      mnos,
      filtersShown,
      filterConfigs,
      candidateFilters,
      appliedFilters,
      additionalFilters,
    } = this.state;
    return (
      <>
        <section className="brand-listing-section" style={{ padding: '0px' }}>
          {loader ? (
            <Loader />
          ) : this.state.noBrandsFound ? (
            <Container maxWidth={false} style={{ padding: 0 }}>
              <Grid
                container
                className="no-brands-found"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item className="text-center">
                  <div className="outer-wrapper">
                    <img
                      src={`${S3_ASSETS_PATH}/images/no-campaigns-found.svg`}
                      alt=""
                    />
                    <h3 className="heading1">Please register a Brand</h3>
                    <p className="heading1">No Brands found</p>
                  </div>
                  {this.props.access.includes('CREATE_BRANDS') ? (
                    <div className="bottom-blk">
                      <Link
                        to="/brand/create"
                        className="primary-btn"
                        data-testid="brandListRegisterBrandLink"
                      >
                        Register a Brand
                      </Link>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container maxWidth={false} style={{ padding: '0px' }}>
              <div className={classes.title}>
                <span>
                  {!loader ? `${brandInfo.totalRecords} Brands` : 'Brands'}
                </span>
              </div>
              <div className={classes.buttons}>
                <Button
                  color="secondary"
                  onClick={this.handleTableFilterToggle}
                  data-testid="tableAddFilterButton"
                >
                  <FontAwesomeIcon icon={faFilter} />
                  <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
                </Button>
                <div className={classes.spacing} />
                {access.includes('CREATE_BRANDS') && (
                  <Button
                    variant="outline"
                    onClick={() => history.push('brand/create')}
                    data-testid="brandListCreateBrandLink"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add Brand
                  </Button>
                )}
              </div>
              {filtersShown && (
                <Grid container style={{ marginBottom: 12 }}>
                  <TableFilter
                    configs={filterConfigs}
                    candidateValues={candidateFilters}
                    appliedValues={appliedFilters}
                    onCandidateValuesChange={this.handleCandidateFiltersChange}
                    onAppliedValuesChange={this.handleAppliedFiltersChange}
                  />
                </Grid>
              )}
              <Grid container justifyContent="center" spacing={0}>
                <Table
                  testid="brandListTable"
                  headRows={headRows}
                  emptyState="no brands to view"
                  className="brands-listing-table"
                  records={{ total: brandInfo.totalRecords }}
                  loading={tableLoader}
                  tableData={brandInfo.records.map((record) => (
                    <BrandListingRow
                      key={record.uid}
                      data={record}
                      mnos={mnos}
                    />
                  ))}
                  handleChangePage={this.handleChangePage}
                  createSortHandler={this.createSortHandler}
                  filter={additionalFilters}
                  pagination={{
                    rowsPerPage: brandInfo.recordsPerPage,
                    page: brandInfo.page,
                    totalRecords: brandInfo.totalRecords,
                  }}
                />
              </Grid>
            </Container>
          )}
          {
            <EinErrorModal
              open={einModal}
              handleClick={(flag) => {
                flag
                  ? this.props.history.push({
                      pathname: `/brands/${einBrandInfo.uid}`,
                      state: {
                        goBackPage: `${this.props.location.pathname}${
                          this.props.location.search
                            ? this.props.location.search
                            : ''
                        }`,
                      },
                    })
                  : this.setState({ einModal: false, einBrandInfo: '' });
              }}
              einBrandInfo={einBrandInfo}
              type="brandList"
            />
          }
        </section>
      </>
    );
  }
}

BrandList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(BrandList);
