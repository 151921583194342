import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';

import { S3_ASSETS_PATH } from '../constants';
import datadog from '../utils/datadog';

function ErrorBoundaryHoc(ComposedComponent, extraInfo) {
  class ErrorBoundaryHoc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        errorInfo: null,
      };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({
        error,
        errorInfo,
      });

      const renderingError = new Error(error.message);
      renderingError.name = 'ReactRenderingError';
      renderingError.stack = errorInfo.componentStack;
      renderingError.cause = error;
      datadog.addError(renderingError);
    }

    render() {
      // console.log('this.props EBHOC', this.props)
      if (this.state.errorInfo) {
        return (
          //      <details style={{ whiteSpace: 'pre-wrap' }}>
          //           {this.state.error && this.state.error.toString()}
          //           <br />
          //           {this.state.errorInfo.componentStack}
          //      </details>
          <Container spacing={0} justify="center">
            <Grid item>
              <div className="technical-error">
                <img
                  src={`${S3_ASSETS_PATH}/images/technical_error.svg`}
                  alt="tech-error-page-bg"
                />
                <h3>Something went wrong here…..</h3>
                <p>
                  Sorry, we are having some technical issues ( as you can see)
                  try to refresh the page, sometime works :)
                </p>
              </div>
            </Grid>
          </Container>
        );
      } else {
        return <ComposedComponent {...this.props} />;
      }
    }
  }
  return ErrorBoundaryHoc;
}
export default ErrorBoundaryHoc;
