import {
  globalPostService,
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';

import mockAppealCategory from './__mocks__/mockAppealCategory.json';
import mockVettingSetting from './__mocks__/mockVettingSetting.json';

export function createNewCspProfileApi(data) {
  return globalPostService('csp/profile', data).then((response) => {
    if (response?.status >= 200 && response?.status < 300) {
      toastFlashMessage(
        'CSP verification form submitted successfully',
        'success'
      );
    }
    return response;
  });
}
export function getHopsApi(data = {}) {
  return globalGetService('csp/dca', { ...data, recordsPerPage: 1000 }).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        const zeroHop = response.data.records;
        return zeroHop;
      }
    }
  );
}
export const getCspProfileApi = (data = {}) => {
  return globalGetService('csp/profile', data);
};
export function appealCspProfileApi(data) {
  return globalPutService('csp/profile/appeal', data).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      toastFlashMessage(
        'CSP verification form re-submitted successfully',
        'success'
      );
    }
    return response;
  });
}
export function getExtVettingProviders() {
  return globalGetService('csp/evpProfile').then((response) => {
    console.log('response evp', response);
    if (response.status >= 200 && response.status < 300) {
      const vettingProviders = response.data.map((item) => {
        return { label: item.evpName, value: item.evpUid };
      });
      this.setState((prevState) => ({
        ...prevState,
        vettingProviders,
      }));
    }
  });
}
export function getAllStockExchanges() {
  return globalGetService('enums/stockExchanges').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const stockExchanges = response.data.map((item) => {
        return { label: item, value: item };
      });
      return stockExchanges;
    }
    return [];
  });
}
export function getCspValidationRulesApi(query = { entityType: `CSP` }) {
  return globalGetService(`csp/validationRule`, query).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      return response.data.records;
    }
  });
}

export const getCountries = async () => {
  const response = await globalGetService('enums/countries');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getAppealCategories = async () => {
  const response = await globalGetService('enums/appealCategories');
  if (response && response.ok) {
    return response.data;
  }
};

export const getVettingSettings = async () => {
  const response = await globalGetService('enums/vettingSettings');
  if (response && response.ok) {
    return response.data;
  }
};
