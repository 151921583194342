import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { Grid, TextField } from '@material-ui/core';

import Section from '../../../../../shared_elements/components/Section';
import { ToolTip } from '../../../../../ui_elements';

interface SupportContactDetailsSectionProps {
  email?: string;
  emailError?: string;
  phone?: string;
  phoneError?: string;
  onEmailChange?: (value: string) => void;
  onEmailBlur?: (value: string) => void;
  onPhoneCountryChange?: (value: string) => void;
  onPhoneChange?: (value: string) => void;
  onPhoneBlur?: (value: string) => void;
}

const SupportContactDetailsSection: FunctionComponent<
  SupportContactDetailsSectionProps
> = ({
  email,
  emailError,
  phone,
  phoneError,
  onEmailChange,
  onEmailBlur,
  onPhoneCountryChange,
  onPhoneChange,
  onPhoneBlur,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Section
      style={{ padding: '28px 35px 30px' }}
      icon={faCircleUser}
      title="Support Contact Details"
    >
      <Flex
        sx={{
          flexDirection: 'column',
          rowGap: '12px',
          width: '100%',
          marginTop: '4px',
        }}
      >
        <Box
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
          }}
        >
          * Required Fields
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div className="form-group-field">
              <TextField
                data-testid="supportContactDetailsEmailInput"
                required
                fullWidth
                error={!!emailError}
                value={email}
                label="Support Email Address"
                onChange={(event) => onEmailChange?.(event.target.value)}
                onBlur={(event) => {
                  setTooltipOpen(false);
                  onEmailBlur?.(event.target.value);
                }}
                onFocus={() => setTooltipOpen(true)}
              />
              <ToolTip
                title="We might send an email to this address for verification purposes"
                open={tooltipOpen}
              />
              {emailError ? <h6 className="error-msg">{emailError}</h6> : null}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="form-group-field">
              <PhoneInput
                data-testid="supportContactDetailsPhoneInput"
                inputClassName={phoneError ? 'error' : ''}
                limitMaxLength
                flags={flags}
                countryOptions={['US', 'IT', 'IN', '|']}
                country="US"
                placeholder="Support Phone Number *"
                value={phone}
                onCountryChange={(value) => onPhoneCountryChange?.(value ?? '')}
                onChange={(value) => onPhoneChange?.(value ?? '')}
                onBlur={(event) =>
                  onPhoneBlur?.((event.target as HTMLInputElement).value)
                }
              />
              {phoneError ? <h6 className="error-msg">{phoneError}</h6> : null}
            </div>
          </Grid>
        </Grid>
      </Flex>
    </Section>
  );
};

export default SupportContactDetailsSection;
