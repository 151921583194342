import { BoxV2 as Box, Flex } from 'portal-commons';
import { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SectionProps {
  style?: CSSProperties;
  icon: IconDefinition;
  title: string;
  action?: ReactNode;
  testId?: string;
}

const Section: FunctionComponent<SectionProps> = ({
  style,
  icon,
  title,
  action,
  testId,
  children,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        padding: '30px 31px 35px',
        border: '1px solid #CCCFD0',
      }}
      style={style}
      data-testid={testId}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '8px',
          marginBottom: '20px',
          color: '#4D5659',
          fontFamily: 'Roboto',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '21px',
        }}
      >
        <FontAwesomeIcon size="lg" icon={icon} />
        <Box sx={{ flex: 1 }}>{title}</Box>
        {action}
      </Flex>
      {children}
    </Flex>
  );
};

export default Section;
