import * as actions from '../actions';
import produce from 'immer';

export default function (
  state = {
    knowledgeBase: [],
    helpBar: [],
    cspData: [],
    termsConditions: [],
    loadingMessage: '',
  },
  action
) {
  switch (action.type) {
    case actions.SET_KNOWLEDGEBASE:
      return {
        ...state,
        knowledgeBase: action.payload,
      };
    case actions.SET_HELPBAR:
      return {
        ...state,
        helpBar: action.payload,
      };
    case actions.SET_DYNAMIC_CONTENT:
      return {
        ...state,
        cspData: action.payload,
      };
    case actions.SET_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: action.payload,
      };
    case actions.SET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload,
      };
    case actions.CLEAR_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: '',
      };
    default:
      return state;
  }
}
