import { Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { faAddressBook } from '@fortawesome/pro-regular-svg-icons';
import { Grid, TextField } from '@material-ui/core';

import Section from '../../../../../shared_elements/components/Section';

interface BusinessContactDetailsSectionProps {
  email?: string;
  emailError?: string;
  onEmailChange?: (value: string) => void;
  onEmailBlur?: (value: string) => void;
}

const BusinessContactDetailsSection: FunctionComponent<
  BusinessContactDetailsSectionProps
> = ({ email, emailError, onEmailChange, onEmailBlur }) => {
  return (
    <Section
      style={{ padding: '28px 35px 30px' }}
      icon={faAddressBook}
      title="Business Contact Details"
    >
      <Flex
        sx={{
          flexDirection: 'column',
          rowGap: '12px',
          width: '100%',
          marginTop: '4px',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            rowGap: '4px',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
          }}
        >
          <div>
            Brand's business contact will need to complete a 2 Factor
            Authentication (2FA) sent to their email address for the brand to
            get verified.
          </div>
          <div>* Required Fields</div>
        </Flex>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div className="form-group-field">
              <TextField
                required
                fullWidth
                error={!!emailError}
                value={email}
                label="Business Email Address"
                onChange={(event) => onEmailChange?.(event.target.value)}
                onBlur={(event) => onEmailBlur?.(event.target.value)}
              />
              {emailError ? <h6 className="error-msg">{emailError}</h6> : null}
            </div>
          </Grid>
        </Grid>
      </Flex>
    </Section>
  );
};

export default BusinessContactDetailsSection;
